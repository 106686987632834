import { IHaiUser, IMembership } from "@hai/sancus-lib";
import { IHaiRoleDefinition } from "./HaiRole";

export function haiUserHasAnyPermissionPrefix(
  haiUser: IHaiUser,
  prefix: string,
  roleDefinitions: IHaiRoleDefinition[],
) {
  const allPermissions = getGlobalPermissions(haiUser, roleDefinitions);
  return allPermissions.find((permission: string) => permission.startsWith(`${prefix}:`)) !== undefined;
}
export function haiUserHasPermission(
  haiUser: IHaiUser,
  permission: string,
  roleDefinitions: IHaiRoleDefinition[],
): boolean {
  const allPermissions = getGlobalPermissions(haiUser, roleDefinitions);
  return allPermissions.find((aPermission: string) => aPermission === permission) !== undefined;
}

export function getAllRoles(membership: IMembership): string[] {
  return membership.roles.concat(...membership.subMemberships.map((sm) => sm.roles));
}

export function getMembershipPermissions(roleDefinitions: IHaiRoleDefinition[], membership: IMembership): string[] {
  return uniq<string>(flatten<string>(membership.roles.map((role) => getPermissionsFromRole(roleDefinitions, role))));
}

function getPermissionsFromRole(roleDefinitions: IHaiRoleDefinition[], role: string): string[] {
  return roleDefinitions.find((rd) => rd.r === role)?.ps ?? [];
}

function getGlobalPermissions(haiUser: IHaiUser, roleDefinitions: IHaiRoleDefinition[]) {
  return uniq<string>(flatten<string>(haiUser.roles.map((role) => getPermissionsFromRole(roleDefinitions, role))));
}

function uniq<T>(listWithRepetitions: T[]): T[] {
  // @ts-ignore
  return [...new Set(listWithRepetitions)];
}

function flatten<T>(listofLists: T[][]): T[] {
  return ([] as T[]).concat(...listofLists);
}
