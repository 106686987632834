import { ITokenExchangeResult } from "@hai/sancus-lib";

import HttpClient from "../common/utils/HttpClient";
import { getHTTPApiUrl } from "../common/utils/ServiceUtils";

import environment from "../environment/Environment";
import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";
import { stringifyUrl } from "@hai/hub-common-portal";
import StorageService, { OKTA_DATA } from "../common/utils/StorageService";

interface IOauthLoginParams {
  idToken: string;
  accessToken: string;
  idp: string;
  params: { [key: string]: string };
}

interface ITokenExchange {
  idp: string;
  idToken: string;
  accessToken: string;
  params: { [key: string]: string };
}

export interface ITokenExchangeResultWithExpiration extends ITokenExchangeResult {
  expires_on: string;
}

interface ILoginService {
  getAzureOAuthURL: () => string;
  getOktaOAuthURL: (oktaSubDomain: string, clientId?: string) => string | undefined;
  loginHaidentity: (props: IOauthLoginParams) => AxiosObservable<ITokenExchangeResult>;
}

const getAzureOAuthURLWithRedirect = (redirect: string): string => {
  const nonce = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  const tenant = environment.azureOAuth2.tenant ?? "haivisionb2cdev";
  const tfp = environment.azureOAuth2.tfp ?? "B2C_1A_AAD_SignUpOrSignInWithAAD";
  const srtHubAppName = environment.azureOAuth2.srtHubAppName ?? "srthub-dev-api";
  const oauthAuthUrl = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${tfp}/oauth2/v2.0/authorize`;

  return stringifyUrl(
    {
      url: oauthAuthUrl,
      query: {
        client_id: environment.azureOAuth2.clientId,
        redirect_uri: window.location.origin + redirect,
        response_mode: "fragment",
        response_type: "code id_token token",
        scope: `https://${tenant}.onmicrosoft.com/${srtHubAppName}/user_impersonation openid profile email`,
      },
    },
    "&" + nonce,
  );
};

const getOktaOAuthURL = (oktaDomain: string, clientId?: string): string | undefined => {
  if (oktaDomain === "") {
    return undefined;
  }
  const nonce = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  const domain = oktaDomain.indexOf(".") < 0 ? `${oktaDomain}.okta.com` : oktaDomain;
  const oauthAuthUrl = `https://${domain}/oauth2/default/v1/authorize`;
  let actualClientId = clientId;
  if (!actualClientId) {
    actualClientId = environment.oktaOAuth2?.clientIds[domain];
  }
  if (!actualClientId) {
    throw new Error(`Unknown clientId for domain: ${oktaDomain}`);
  }
  StorageService.set(OKTA_DATA, { oktaDomain: domain, clientId: actualClientId });
  return stringifyUrl({
    url: oauthAuthUrl,
    query: {
      client_id: actualClientId,
      redirect_uri: window.location.origin + "/login/okta",
      response_mode: "fragment",
      response_type: "token",
      state: domain,
      scope: "openid",
      nonce: nonce,
    },
  });
};

const getAzureOAuthURL = (): string => {
  return getAzureOAuthURLWithRedirect("/login/azure");
};

const loginHaidentity = (props: IOauthLoginParams): AxiosObservable<ITokenExchangeResult> => {
  const sancusBase = getHTTPApiUrl("sancus");
  const payload: ITokenExchange = {
    idp: props.idp,
    idToken: props.idToken,
    accessToken: props.accessToken,
    params: props.params,
  };

  return HttpClient.post<ITokenExchangeResult>(sancusBase + "/auth/exchange", payload);
};

export const LoginService: ILoginService = {
  getAzureOAuthURL,
  getOktaOAuthURL,
  loginHaidentity,
};
