import React, { LazyExoticComponent, Suspense, useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { UtcComponent } from "../common/utc/Utc.component";
import { Loading } from "../common/components/Loading/Loading.component";
import { SessionContext } from "../common/session/Session.context";
import LayoutComponent, { LayoutType } from "./Layout.component";

interface IAppRoutes {
  [key: string]: IAppRoute;
}

interface IAppRoute {
  title: string;
  to: string;
  visible?: boolean;
  LazyComponent: LazyExoticComponent<any>;
  iconName?: string;
  isPublic?: boolean;
  subpaths?: { to: string; title: string }[];
  defaultPath?: string;
}

export const LOGIN_ROUTE = "login";
export const DEFAULT_ROUTE = "hermesHealth";
export const HERMES_HEALTH_ROUTE = "hermesHealth";
export const CLUSTERS_ROUTE = "clusters";
export const LIVE_WORKFLOW_ROUTE = "livewf";
export const ABOUT_ROUTE = "about";
export const APP_INSIGHTS_ROUTE = "appinsights";

export const AppRoutes: IAppRoutes = {
  [LOGIN_ROUTE]: {
    title: "Login",
    to: "/login",
    visible: false,
    isPublic: true,
    LazyComponent: React.lazy(() => import("../login/Login.view")),
    defaultPath: "/login",
    subpaths: [
      {
        to: "/azure",
        title: "Login",
      },
      {
        to: "/okta",
        title: "Login",
      },
    ],
  },
  [HERMES_HEALTH_ROUTE]: {
    title: "Hermes Health",
    to: "/hermes-health",
    visible: true,
    LazyComponent: React.lazy(() => import("../hermes-health/HermesHealth.routing")),
    iconName: "AccessObserver",
  },
  [CLUSTERS_ROUTE]: {
    title: "Clusters",
    to: "/clusters",
    visible: true,
    LazyComponent: React.lazy(() => import("../clusters/Clusters.routing")),
    iconName: "Persons",
    defaultPath: "/clusters/map",
    subpaths: [
      {
        to: "/map",
        title: "Map",
      },
      {
        to: "/list",
        title: "List",
      },
    ],
  },
  [LIVE_WORKFLOW_ROUTE]: {
    title: "Live WorkFlows",
    to: "/live-wf",
    visible: true,
    LazyComponent: React.lazy(() => import("../live-workflows/LiveWorkflows.routing")),
    iconName: "Route",
  },
  home: {
    title: "Home",
    to: "/home",
    visible: false,
    LazyComponent: React.lazy(() => import("../home/Home.view")),
    iconName: "AccessAdmin",
  },
  dummy: {
    title: "Dummy",
    to: "/dummy",
    visible: false,
    LazyComponent: React.lazy(() => import("../dummy/Dummy.routing")),
    iconName: "AccessOperator",
  },
  haiuitest: {
    title: "HaiUI test",
    to: "/haiui",
    visible: false,
    isPublic: true,
    LazyComponent: React.lazy(() => import("../hai-ui-test/HaiUiTest.view")),
    iconName: "AccessObserver",
  },
  [ABOUT_ROUTE]: {
    title: "About",
    to: "/about",
    visible: false,
    LazyComponent: React.lazy(() => import("../about/About.view")),
    iconName: "Admin",
  },
  [APP_INSIGHTS_ROUTE]: {
    title: "AppInsights",
    to: "/appinsights",
    visible: true,
    LazyComponent: React.lazy(() => import("../app-insights/AppInsights.routing")),
    iconName: "Index",
  },
};

interface IPrivateRouteProps {
  component: LazyExoticComponent<any>;
  isPublic?: boolean;
  [key: string]: any;
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({ component, isPublic, ...rest }) => {
  const value = useContext(SessionContext);
  const isAuthenticated = !!value?.getHaiData()?.hat;

  const finalComponent = isAuthenticated || isPublic ? component : AppRoutes.login.LazyComponent;

  return (
    <LayoutComponent type={isAuthenticated ? LayoutType.REGULAR_PAGE : LayoutType.FULL_PAGE}>
      <Suspense fallback={<Loading />}>
        {isAuthenticated && <UtcComponent />}
        <Route component={finalComponent} {...rest} />
      </Suspense>
    </LayoutComponent>
  );
};

export const AppRouting: React.FC = () => {
  return (
    <>
      <Switch>
        <Redirect to="/login" from="/" exact={true} />
        {Object.values(AppRoutes).map(({ to, LazyComponent, isPublic }, index) => (
          <PrivateRoute key={`route${index}`} path={to} component={LazyComponent} isPublic={isPublic} />
        ))}
      </Switch>
    </>
  );
};
