import environment from "../../environment/Environment";

const baseUrl = environment.baseUrl;
const { serviceOverrides } = environment;

export const getHTTPApiUrl = (serviceName: string) => {
  if (serviceOverrides && !!serviceOverrides[serviceName]) {
    const serviceUrl = serviceOverrides[serviceName];
    return `${serviceUrl}`;
  }
  return `${baseUrl}/${serviceName}`;
};

export const getWSApiUrl = (serviceName: string) => {
  if (serviceOverrides && !!serviceOverrides[serviceName]) {
    let serviceUrl = serviceOverrides[serviceName];
    if (serviceUrl.indexOf("//") !== -1) {
      serviceUrl = serviceUrl.split("//")[1];
    }
    return `${getWebSocketProtocol()}//${serviceUrl}/live`;
  }
  let domainName: string = window.location.host;
  if (environment.baseUrl) {
    domainName = environment.baseUrl.split("//")[1];
  }
  return `${getWebSocketProtocol()}//${domainName}/${serviceName}/live`;
};

// Firefox was not connecting if ws. Left previous commented in case they fix this in the future
export const getWebSocketProtocol = () => "wss:"; //(window.location.protocol === "http:" ? "ws:" : "wss:");
