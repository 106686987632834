const SECONDS_IN_MILLISECONDS = 1_000;
const MINUTES_IN_SECONDS = 60;
const HOUR_IN_MINUTES = 60;
const DAY_IN_HOURS = 24;
const HOURS_IN_SECONDS = HOUR_IN_MINUTES * MINUTES_IN_SECONDS;
const DAYS_IN_SECONDS = DAY_IN_HOURS * HOURS_IN_SECONDS;

const numTwoDigits = (num: number) => String(num).padStart(2, "0");

const timeFormat = new Intl.DateTimeFormat("en", {
  hour12: false,
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
});

const dateTimeFormat = new Intl.DateTimeFormat("en", {
  hour12: false,
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
});

export function getPrintableDateTime(datetime?: string | Date) {
  if (!datetime) {
    return "-";
  }
  const date = new Date(datetime);
  if (isNaN(date.valueOf())) {
    return "-";
  }

  return dateTimeFormat.format(date);
}

export function getPrintableTime(datetime?: string | Date) {
  if (!datetime) {
    return "-";
  }

  const date = new Date(datetime);
  if (isNaN(date.valueOf())) {
    return "-";
  }

  return timeFormat.format(date);
}

export function getElapsedTime(from?: string, to?: string) {
  if (!from) {
    return "-";
  }

  const fromDate = new Date(from);
  const toDate = !to ? new Date() : new Date(to);
  const diff = (toDate.valueOf() - fromDate.valueOf()) / SECONDS_IN_MILLISECONDS; // seconds
  return secondsToHhMmSs(diff);
}

export function getElapsedTimeWithDays(from?: string, to?: string) {
  if (!from) {
    return "-";
  }

  const fromDate = new Date(from);
  const toDate = !to ? new Date() : new Date(to);
  const diff = (toDate.valueOf() - fromDate.valueOf()) / SECONDS_IN_MILLISECONDS; // seconds
  return secondsToDdHhMmSs(diff);
}

export function secondsToHhMmSs(seconds: number) {
  const hours = Math.floor(seconds / HOURS_IN_SECONDS);
  seconds %= HOURS_IN_SECONDS;
  const minutes = Math.floor(seconds / MINUTES_IN_SECONDS);
  seconds %= MINUTES_IN_SECONDS;
  seconds = Math.floor(seconds);

  if (hours > 0) {
    return `${hours}h ${minutes}m ${seconds}s`;
  } else if (minutes > 0) {
    return `${minutes}m ${seconds}s`;
  } else {
    return `${seconds}s`;
  }
}

export function secondsToDdHhMmSs(seconds: number) {
  const days = Math.floor(seconds / DAYS_IN_SECONDS);
  seconds %= DAYS_IN_SECONDS;
  const hours = Math.floor(seconds / HOURS_IN_SECONDS);
  seconds %= HOURS_IN_SECONDS;
  const minutes = Math.floor(seconds / MINUTES_IN_SECONDS);
  seconds %= MINUTES_IN_SECONDS;
  seconds = Math.floor(seconds);

  const minutesTwoDigits = numTwoDigits(minutes);
  const secondsTwoDigits = numTwoDigits(seconds);

  if (days > 0) {
    return `${days}d ${hours}h ${minutesTwoDigits}m ${secondsTwoDigits}s`;
  }
  if (hours > 0) {
    return `${hours}h ${minutesTwoDigits}m ${secondsTwoDigits}s`;
  } else if (minutes > 0) {
    return `${minutesTwoDigits}m ${secondsTwoDigits}s`;
  } else {
    return `${secondsTwoDigits}s`;
  }
}

/**
 * Returns the difference between user's local timezone and UTC.
 *
 * @returns "UTC+XXh" or "UTC+XXhYYm"
 */
export function getUtcDiff(): string {
  const date = new Date();
  // The number of minutes returned by getTimezoneOffset() is positive if the local time zone is behind UTC,
  // and negative if the local time zone is ahead of UTC.For example, for UTC + 10, -600 will be returned.
  const offset = date.getTimezoneOffset() * -1;

  const hours = offset / HOUR_IN_MINUTES;
  const minutes = offset % HOUR_IN_MINUTES;

  const hoursString = hours >= 0 ? `+${hours}h` : `${hours}h`;
  const minutesString = minutes !== 0 ? `${Math.abs(minutes)}m` : "";
  return `UTC${hoursString + minutesString}`;
}

/**
 * Function that takes normal Date and transforms to local time with UTC offset
 */
export function getFakeUtcDateFromLocal(date: Date): Date {
  const ms = date.getTime();
  const offset = date.getTimezoneOffset() * HOUR_IN_MINUTES * SECONDS_IN_MILLISECONDS;
  const fakeDate = new Date(ms + offset);

  return fakeDate;
}

/**
 * Function that takes fake UTC Date and transforms to local time without UTC offset
 */
export function getLocalDateFromFakeUtc(date: Date): Date {
  const ms = date.getTime();
  const offset = date.getTimezoneOffset() * HOUR_IN_MINUTES * SECONDS_IN_MILLISECONDS;
  const fakeDate = new Date(ms - offset);

  return fakeDate;
}

/**
 * Function that takes normal Date and transforms to local time with UTC offset
 */
export function getFakeUtcTimeFromLocal(date: Date): string {
  const fakeDate = getFakeUtcDateFromLocal(date);
  return fakeDate.toISOString();
}

/**
 * Function that takes fake UTC Date and transforms to local time without UTC offset
 */
export function getLocalTimeFromFakeUtc(date: Date): string {
  const fakeDate = getLocalDateFromFakeUtc(date);

  return fakeDate.toISOString();
}
