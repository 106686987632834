import React from "react";

import SidebarComponent from "../common/components/Sidebar/Sidebar.component";
import style from "./App.module.scss";

export enum LayoutType {
  FULL_PAGE = "fullPage",
  REGULAR_PAGE = "regularPage",
}

const LayoutComponent = (props: { type: LayoutType; children: any }) => {
  return (
    <div className={style.LayoutContainer}>
      {props.type !== LayoutType.FULL_PAGE && <SidebarComponent />}
      <main>
        <div className={style.ViewContent}>{props.children}</div>
      </main>
    </div>
  );
};

export default LayoutComponent;
