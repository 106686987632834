const appVersion = require("../../package.json").version;

declare global {
  interface Window {
    _env_?: IEnvironment;
  }
}

interface IEnvironment {
  name: string;
  type: string;
  baseUrl: string;
  azureOAuth2: {
    authorizeURL: string;
    clientId: string;
    tenant?: string;
    tenantId?: string;
    srtHubAppName?: string;
    tfp: string;
  };
  oktaOAuth2?: {
    clientIds: {
      [domain: string]: string;
    };
  };
  default?: boolean;
  serviceOverrides?: {
    [serviceName: string]: string;
  };
  version: {
    name: string;
    branch: string;
  };
  appVersion: string;
}

const defaultEnv: IEnvironment = {
  name: "Arkadia - Olympia frontend",
  type: "dev",
  baseUrl: "https://olympiadev.eastus.cloudapp.azure.com",
  azureOAuth2: {
    authorizeURL:
      "https://haivisionb2cdev.b2clogin.com/haivisionb2cdev.onmicrosoft.com/B2C_1A_AAD_SignUpOrSignInWithAAD/oauth2/v2.0/authorize",
    clientId: "65ba89fd-09a1-44a4-9865-6951e1bcd6e5",
    tenant: "haivisionb2cdev",
    tfp: "B2C_1A_AAD_SignUpOrSignInWithAAD",
  },
  oktaOAuth2: {
    clientIds: {
      "dev-44576125.okta.com": "0oa58e2nhuDNusFgC5d7",
    },
  },
  default: true,

  serviceOverrides: {
    // hermes: 'http://localhost:17040', // if you are running any service locally, you can override its address here
    // jupiter: 'http://localhost:17050',
  },
  version: {
    name: "local",
    branch: "dev",
  },
  appVersion,
};

let environment = window._env_ as IEnvironment;
if (!environment || environment.default === true) {
  // eslint-disable-next-line no-console
  console.warn(
    "%cWarning!%cUsing default vars!",
    "background: #000; color: #fff; font-size: medium; font-weight: bold; padding: 5px; margin: 5px 0;",
    "background: #000; color: #fff; font-size: medium; padding: 5px",
  );
}

if (!environment) {
  environment = defaultEnv;
} else {
  delete window._env_;
}

environment.appVersion = appVersion;

export default environment;
