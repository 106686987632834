import { CreateHaiThemeContext, ThemeType } from "@hai/ui-react";
import React from "react";
import { BrowserRouter } from "react-router-dom";

import { SessionContextProvider } from "../common/session/Session.context";
import { UtcContextProvider } from "../common/utc/Utc.context";
import { HermesHealthContextProvider } from "./HermesHealthContext";
import { AppRouting } from "./App.routing";

import "../index.scss";

const AppView: React.FC = () => {
  const ThemeContext = CreateHaiThemeContext("argon", ThemeType.DARK);

  return (
    <SessionContextProvider>
      <HermesHealthContextProvider>
        <ThemeContext.Provider value="argon">
          <UtcContextProvider>
            <BrowserRouter>
              <AppRouting />
            </BrowserRouter>
          </UtcContextProvider>
        </ThemeContext.Provider>
      </HermesHealthContextProvider>
    </SessionContextProvider>
  );
};

export default AppView;
