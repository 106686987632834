import { ITokenExchangeResult } from "@hai/sancus-lib";

export const HAI_DATA = "haiData";
export const OKTA_DATA = "oktaData";

type StorageKey = typeof HAI_DATA | typeof OKTA_DATA;

// overload functions to strongly type the result
function get(key: typeof HAI_DATA): Required<ITokenExchangeResult>;
function get(key: typeof OKTA_DATA): Required<IOktaData>;
function get(key: StorageKey) {
  const value = localStorage.getItem(key);
  if (value) {
    return JSON.parse(value);
  }
}

const set = (key: StorageKey, value: Object) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const remove = (key: StorageKey) => {
  localStorage.removeItem(key);
};

export default {
  set,
  get,
  remove,
};
export interface IOktaData {
  oktaDomain: string;
  clientId: string;
}
