import { AxiosRequestConfig, AxiosResponse } from "axios";
import Axios from "axios-observable";
import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";
import { forkJoin, from, Observable } from "rxjs";
import { logger } from "shared/logger";

import StorageService, { HAI_DATA } from "./StorageService";

export interface IRequestOptions extends AxiosRequestConfig {
  skipAuthHeader?: boolean;
}

const get = <T>(url: string, opts?: IRequestOptions): AxiosObservable<T> => {
  return Axios.get<T>(url, getRequestConfig(opts));
};

const getAll = <T>(urls: string[], opts?: IRequestOptions): Observable<AxiosResponse[]> => {
  return forkJoin(urls.map((url) => from(Axios.get<T>(url, getRequestConfig(opts)))));
};

const post = <T>(url: string, payload: any, opts?: IRequestOptions): AxiosObservable<T> => {
  return Axios.post<T>(url, payload, getRequestConfig(opts));
};

const put = <T>(url: string, payload: any, opts?: IRequestOptions): AxiosObservable<T> => {
  return Axios.put<T>(url, payload, getRequestConfig(opts));
};

const del = <T>(url: string, opts?: IRequestOptions): AxiosObservable<T> => {
  return Axios.delete<T>(url, getRequestConfig(opts));
};

interface IConfig {
  headers: {
    "Content-Type"?: string;
    Authorization?: string;
  };
  transformRequest?: [(data: any) => any];
}

const getRequestConfig = (opts?: IRequestOptions): AxiosRequestConfig => {
  const config: IConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  Axios.interceptors.response.use(
    (res: AxiosResponse) => res,
    (err: any) => {
      if (err && err.response && err.response.status === 401) {
        StorageService.remove(HAI_DATA);
        logger.error("Token expired");
        window.location.href = "/login";
      }
      return Promise.reject(err);
    },
  );

  const { skipAuthHeader, ...otherConf } = opts || {};

  const { haiUser, hat } = StorageService.get(HAI_DATA) ?? {};
  if (!skipAuthHeader && haiUser && hat) {
    config.headers.Authorization = `Bearer ${hat}`;
  }

  return { ...otherConf, ...config };
};

export default {
  get,
  getAll,
  post,
  put,
  del,
};
