import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  RadioButton,
  RadioButtonGroup,
} from "@hai/ui-react";
import React, { useContext, useState } from "react";

import styles from "./Utc.component.module.scss";
import { UtcContext } from "./Utc.context";
import { getUtcDiff } from "../utils/DateUtils";

const UTC_DIFF = getUtcDiff();

export const UtcComponent = () => {
  const { showUtc, setShowUtc } = useContext(UtcContext);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Modal centered show={showModal} className={styles.modal}>
        <ModalHeader>
          <ModalTitle>Timezone Selector</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>Select the timezone to display all the dates in Arkadia:</p>
          <RadioButtonGroup
            checkedValue={showUtc ? "UTC" : "LOCAL"}
            onChange={(e: any) => {
              const value = e.target.value;
              setShowUtc(value === "UTC");
            }}
          >
            <div>
              <RadioButton name="rd-group-1" value="UTC">
                Show times in UTC
              </RadioButton>
            </div>
            <div>
              <RadioButton name="rd-group-1" value="LOCAL">
                {`Show times in your local timezone (${UTC_DIFF})`}
              </RadioButton>
            </div>
          </RadioButtonGroup>
        </ModalBody>
        <ModalFooter>
          <Button variant="primary" onClick={() => setShowModal(false)}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
      <div className={styles.utcComponent}>
        <button onClick={() => setShowModal(true)}>
          <Icon className="mr-1" iconname="Calendar" />
          {showUtc ? "UTC" : `LOCAL`}
        </button>
      </div>
    </>
  );
};
