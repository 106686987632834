import React, { createContext, useCallback, useContext, useEffect, useReducer, useState } from "react";
import { SessionContext } from "../common/session/Session.context";
import { IHealth } from "../hermes-health/HermesHealth.model";
import { getHealth } from "../hermes-health/hermesHealthService";

const CHECK_INTERVAL = 10_000; // milliseconds

interface IHealthWithDate extends IHealth {
  date: Date;
  countWithoutUpdate: number;
}

interface IHermesHealthContext {
  health?: IHealthWithDate[];
  loadDate?: Date;
  forceUpdate: () => void;
}

const OPACITIES = [1, 0.75, 0.7, 0.65, 0.6, 0.55, 0.5, 0.45, 0.4, 0.35, 0.3, 0.25, 0.2];
export function getOpacity(count: number): number {
  return OPACITIES[count];
}

export const HermesHealthContext = createContext<IHermesHealthContext>({
  forceUpdate: () => {},
});

export const HermesHealthContextProvider: React.FC = (props) => {
  const [health, setHealth] = useState<Map<string, IHealthWithDate>>(new Map());
  const [loadDate, setLoadDate] = useState(new Date());
  const [forceUpdateCounter, forceUpdate] = useReducer((x) => x + 1, 0);

  const sessionProvider = useContext(SessionContext);

  const updateHealth = useCallback(() => {
    if (sessionProvider.getHaiData() !== undefined) {
      getHealth().subscribe((res) => {
        const date = new Date();
        setHealth((health) => {
          Array.from(health.entries()).forEach(([key, value]) => {
            const countWithoutUpdate = value.countWithoutUpdate + 1;
            if (countWithoutUpdate <= OPACITIES.length) {
              health.set(key, { ...value, countWithoutUpdate });
            } else {
              health.delete(key);
            }
          });

          const currentHealth = {
            ...res.data,
            date,
            countWithoutUpdate: 0,
          };
          health.set(res.data.instanceId, currentHealth);
          return new Map(health);
        });
        setLoadDate(date);
      });
    }
  }, [sessionProvider]);

  useEffect(() => {
    updateHealth();
    const interval = setInterval(() => {
      updateHealth();
    }, CHECK_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, [updateHealth, forceUpdateCounter]);

  return (
    <HermesHealthContext.Provider value={{ health: Array.from(health.values()), loadDate, forceUpdate }}>
      {props.children}
    </HermesHealthContext.Provider>
  );
};
