import i18next from "i18next";
import i18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/default.json";
import translationES from "./locales/es/default.json";

// More info:
// https://react.i18next.com/latest/using-with-hooks

i18next
  .use(initReactI18next)
  .use(i18nextBrowserLanguageDetector)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en: { translation: translationEN },
      es: { translation: translationES },
    },
    detection: {
      order: ["querystring", "localStorage", "navigator"],
      lookupQuerystring: "lng",
      lookupLocalStorage: "i18nLng",
    },
    fallbackLng: "en",
    interpolation: { escapeValue: false }, // not needed for react as it escapes by default
    // debug: true,
  });
