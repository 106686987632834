import React from "react";
import ReactDOM from "react-dom";
import { isMainWindow } from "@hai/hub-common-portal";

import { logger } from "shared/logger";

import AppView from "./app/App.view";

import "./i18n/i18next";
import "./reloadAzureToken";

if (isMainWindow()) {
  logger.log(
    "\n%c Hai%cvision %c\n",
    "background: #000; color: #fff; line-height: 35px; font-weight: bold; padding: 5px 0; font-size: x-large",
    "background: #000; color: #fff; line-height: 35px; padding: 5px 0; font-size: x-large",
    "background: #fff;",
  );
}

ReactDOM.render(<AppView />, document.getElementById("root"));
