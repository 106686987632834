import { logger } from "shared/logger";
import StorageService, { HAI_DATA } from "./StorageService";

const KEEP_ALIVE_MESSAGE = "h"; // health
// it reconnects after 1 minute, send keep alive after 45 seconds without messages
const KEEP_ALIVE_MESSAGE_INTERVAL = 45_000;

export class WSMessage<T> {
  constructor(public type: WSMessageType, public data?: T) {}
}

export enum WSMessageType {
  AUTH_ERROR = "AUTH_ERROR",
  COMPONENT_HEALTH = "COMPONENT_HEALTH",
  LIVE_WORKFLOW_STATUS = "LIVE_WORKFLOW_STATUS",
  LIVE_WORKFLOW_DELETED = "LIVE_WORKFLOW_DELETED",
  CLUSTER_SERVICES_UPDATE = "CLUSTER_SERVICES_UPDATE",

  IPTICKET_CREATED = "ipticket-created",
  IPTICKET_ASSIGNED = "ipticket-assigned",
  IPTICKET_REMOVED = "ipticket-removed",
  FORWARDER_CREATED = "forwarder-created",
  FORWARDER_CONNECTED = "forwarder-connected",
  FORWARDER_REMOVED = "forwarder-removed",
  PROBE_START = "PROBE_START",
  PROBE_STOP = "PROBE_STOP",
}

interface IWebsocketOpts {
  messageTypes?: WSMessageType[];
}

export class WebsocketClient<T> {
  private ws: WebSocket | null = null;
  // only for debugging and tracing
  private readonly wsPath: string;
  private url: string;
  private reconnecting = false;
  private closedByUser = false;
  private retries = 0;
  private keepAliveInterval = -1;

  constructor(url: string, cb: (wsMessage: WSMessage<T>) => void, public opts?: IWebsocketOpts) {
    this.wsPath = url.substring(url.lastIndexOf("/"));
    this.url = url;
    const token = StorageService.get(HAI_DATA)?.hat ?? "";
    if (!token) {
      logger.warn("Websocket connection could not be created because user was not authenticated");
      return;
    }
    url += `?token=${token}`;
    this.connect(url, cb);
  }

  public close() {
    this.closedByUser = true;
    if (this.ws) {
      logger.log("Closing Websocket Connection from this end " + this.url);
      this.ws.onmessage = (ev) => {
        // nothing to see here
      };
      try {
        this.ws.close(1_000);
      } catch (e) {
        logger.warn("Could not close " + this.url);
      }
    }
  }

  private connect(url: string, cb: (wsMessage: WSMessage<T>) => void) {
    this.ws = new WebSocket(url);

    this.ws.onopen = () => {
      logger.log("createWebsocketConnection [onopen] " + this.url);
      this.startKeepAliveInterval();
    };

    this.ws.onclose = (ev: CloseEvent) => {
      this.stopKeepAliveInterval();

      if (this.ws) {
        // Reconnects if close code !== 1000 (https://github.com/Luka967/websocket-close-codes)
        this.ws.close();
        if (ev.code !== 1_000 && !this.reconnecting && !this.closedByUser) {
          this.reconnecting = true;
          setTimeout(() => {
            logger.log("createWebsocketConnection [onclose] reconnecting to " + this.url);
            this.reconnecting = false;
            this.retries++;
            this.connect(url, cb);
          }, 1_000 + this.retries * 250);
        }
      }
    };

    this.ws.onmessage = async (message) => {
      this.startKeepAliveInterval();
      // it's a thumbnail
      if (message.data instanceof Blob) {
        cb(message.data as any);

        // it's a regular message
      } else {
        const wsMessage = JSON.parse(message.data) as WSMessage<T>;
        if (wsMessage.type === WSMessageType.AUTH_ERROR) {
          logger.error("Authentication error " + this.wsPath);
        } else {
          // Filters by message type if requested
          if (!this.opts?.messageTypes || this.opts?.messageTypes.includes(wsMessage.type)) {
            cb(wsMessage);
          }
        }
      }
    };
  }

  /**
   * Send a message (anything should work) to keep connection alive and avoid reconnections
   */
  private startKeepAliveInterval() {
    this.stopKeepAliveInterval();
    this.keepAliveInterval = window.setInterval(() => {
      this.ws?.send(KEEP_ALIVE_MESSAGE);
    }, KEEP_ALIVE_MESSAGE_INTERVAL);
  }

  private stopKeepAliveInterval() {
    clearInterval(this.keepAliveInterval);
  }
}
