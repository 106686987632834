import React from "react";

const HubSupportPortalLogoComponent: React.FC<{ width?: string }> = ({ width }) => {
  return (
    <div style={{ width: width ? width : "100%" }}>
      <span>Hub </span>
      <span>Support </span>
      <span>Portal</span>
    </div>
  );
};

export default HubSupportPortalLogoComponent;
