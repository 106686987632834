import { Spinner } from "@hai/ui-react";
import { SpinnerSize } from "@hai/ui-react/dist/components/Spinner/Spinner";
import React from "react";

import styles from "./Loading.module.scss";

export const Loading = () => (
  <div className={styles.Loading}>
    <Spinner size={SpinnerSize.LARGE} />
  </div>
);
