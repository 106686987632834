import { StorageService } from "@hai/hub-common-portal";
import React, { useEffect, useState } from "react";

const STORAGE_SHOW_UTC_KEY = "arkadia-show-utc";

interface IUtcContext {
  showUtc: boolean;
  setShowUtc: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UtcContext = React.createContext<IUtcContext>({ showUtc: false, setShowUtc() {} });

export const UtcContextProvider = (props: { children: JSX.Element | JSX.Element[] }) => {
  const [showUtc, setShowUtc] = useState(StorageService.get<boolean>(STORAGE_SHOW_UTC_KEY) ?? true);

  useEffect(() => {
    StorageService.set<boolean>(STORAGE_SHOW_UTC_KEY, showUtc);
  }, [showUtc]);

  return <UtcContext.Provider value={{ showUtc, setShowUtc }}>{props.children}</UtcContext.Provider>;
};
