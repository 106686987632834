import { InfoFooter, Sidebar as HaiSidebar } from "@hai/ui-react";
import React, { useContext, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { AppRoutes, LOGIN_ROUTE } from "../../../app/App.routing";
import environment from "../../../environment/Environment";
import { SessionContext } from "../../session/Session.context";
import { hasPermissionToReadRouteKey } from "../../utils/SecurityCheck";
import StorageService, { HAI_DATA } from "../../utils/StorageService";
import HubSupportPortalLogoComponent from "../HubSupportPortalLogo/HubSupportPortalLogo.component";
import bg from "./sidebar_bg_dark.png";

const removeForwardSlashes = (str: string): string => str.replaceAll("/", "");

const SidebarComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const sessionContext = useContext(SessionContext);
  const { haiUser, roleDefinitions } = sessionContext.getHaiData();

  const navigate = (path: string) => {
    history.push(path);
  };

  const signOut = () => {
    StorageService.remove(HAI_DATA);
    history.push(AppRoutes[LOGIN_ROUTE].to);
  };

  const SidebarMenus = useMemo(
    () =>
      Object.keys(AppRoutes)
        .filter((routeKey) => hasPermissionToReadRouteKey(routeKey, haiUser, roleDefinitions))
        .map((routeKey) => AppRoutes[routeKey])
        .filter((route) => route.visible !== false)
        .map((route) => {
          (route as any).key = removeForwardSlashes(route.to);

          if (route.subpaths) {
            (route as any).menuItems = route.subpaths.map((sp) => {
              return {
                caption: sp.title,
                key: removeForwardSlashes(sp.to),
                to: route.to + sp.to,
              };
            });
          }

          return route;
        }),
    [haiUser, roleDefinitions],
  );

  return (
    <HaiSidebar
      logo={<HubSupportPortalLogoComponent width="71px" />}
      menuContent={SidebarMenus}
      infoTitle="Welcome"
      userName={haiUser?.userName ?? ""}
      defaultActive={location.pathname}
      onSelect={navigate}
      onSignOut={signOut}
      backgroundImage={bg}
      onCollapse={(e) => {
        const wasCollapsed =
          e.target.parentElement.parentElement.parentElement.parentElement.className.includes("collapse-sidebar");
        const rootElement = document.querySelector("#root");
        if (wasCollapsed) {
          rootElement?.classList.remove("collapse-sidebar");
        } else {
          rootElement?.classList.add("collapse-sidebar");
        }
      }}
      footer={
        <InfoFooter>
          <div>Olympia: {environment.version?.name}</div>
          <div>Arkadia: {environment.appVersion}</div>
        </InfoFooter>
      }
    />
  );
};

export default SidebarComponent;
