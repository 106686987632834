import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";

import HttpClient from "../common/utils/HttpClient";
import { getHTTPApiUrl, getWSApiUrl } from "../common/utils/ServiceUtils";
import { WebsocketClient, WSMessage, WSMessageType } from "../common/utils/WebSocketClient";
import { IComponentHealthUpdateMessage } from "./Component.model";
import { IHealth } from "./HermesHealth.model";

const baseUrl = getHTTPApiUrl("hermes");
const baseWsUrl = getWSApiUrl("hermes");

export const getHealth = (): AxiosObservable<IHealth> => {
  return HttpClient.get(`${baseUrl}/olympia-health`);
};

export const subscribeHealth = (
  cb: (wsMessage: WSMessage<IComponentHealthUpdateMessage>) => void,
): WebsocketClient<IComponentHealthUpdateMessage> => {
  return new WebsocketClient(baseWsUrl, cb, { messageTypes: [WSMessageType.COMPONENT_HEALTH] });
};
