import { IHaiUser } from "@hai/sancus-lib";
import {
  ABOUT_ROUTE,
  APP_INSIGHTS_ROUTE,
  CLUSTERS_ROUTE,
  DEFAULT_ROUTE,
  LIVE_WORKFLOW_ROUTE,
} from "../../app/App.routing";
import { IHaiRoleDefinition } from "../HaiRole";
import { haiUserHasPermission } from "../HaiUser";

export function hasPermissionToReadRouteKey(
  routeKey: string,
  haiUser: IHaiUser | undefined,
  roleDefinitions: IHaiRoleDefinition[],
) {
  if (haiUser === undefined) {
    return false;
  }
  if (haiUser.roles === undefined) {
    return true;
  }
  if (routeKey === "hermesHealth") {
    return haiUserHasPermission(haiUser, "hermes:read", roleDefinitions);
  } else if (routeKey === "clusters") {
    return haiUserHasPermission(haiUser, "hermes:read", roleDefinitions);
  } else if (routeKey === "livewf") {
    return haiUserHasPermission(haiUser, "jupiter:read", roleDefinitions);
  } else if (routeKey === APP_INSIGHTS_ROUTE) {
    return haiUserHasPermission(haiUser, "jupiter:read", roleDefinitions);
  } else {
    return routeKey === "about" || routeKey === "login";
  }
}

export function selectRouteKey(haiUser: IHaiUser, roleDefinitions: IHaiRoleDefinition[]) {
  if (hasPermissionToReadRouteKey(DEFAULT_ROUTE, haiUser, roleDefinitions)) {
    return DEFAULT_ROUTE;
  } else if (hasPermissionToReadRouteKey(CLUSTERS_ROUTE, haiUser, roleDefinitions)) {
    return CLUSTERS_ROUTE;
  } else if (hasPermissionToReadRouteKey(LIVE_WORKFLOW_ROUTE, haiUser, roleDefinitions)) {
    return LIVE_WORKFLOW_ROUTE;
  } else if (hasPermissionToReadRouteKey(APP_INSIGHTS_ROUTE, haiUser, roleDefinitions)) {
    return APP_INSIGHTS_ROUTE;
  } else {
    return ABOUT_ROUTE;
  }
}
