import { ITokenExchangeResult } from "@hai/sancus-lib";
import { Button, Form, FormContext, FormControl, FormControlError, FormGroup, Spinner } from "@hai/ui-react";
import { SpinnerSize } from "@hai/ui-react/dist/components/Spinner/Spinner";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Redirect, useHistory, useLocation, withRouter } from "react-router-dom";

import { AppRoutes } from "../app/App.routing";
import HaivisionLogoComponent from "../common/components/HaivisionLogo/HaivisionLogo.component";
import HubSupportPortalLogoComponent from "../common/components/HubSupportPortalLogo/HubSupportPortalLogo.component";
import { SessionContext } from "../common/session/Session.context";
import { selectRouteKey } from "../common/utils/SecurityCheck";
import StorageService, { HAI_DATA, OKTA_DATA } from "../common/utils/StorageService";
import environment from "../environment/Environment";
import { ITokenExchangeResultWithExpiration, LoginService } from "./Login.service";
import style from "./Login.module.scss";

enum IdentityProvider {
  AZURE,
  OKTA,
}

export const getTokenExpirationDate = (sancusLoggedUser: ITokenExchangeResult): string => {
  const expirationDate = new Date(Date.now() + (sancusLoggedUser.expires || 3_600) * 1_000);
  return expirationDate.toISOString();
};

const getIdentityProviderUrl = (provider: IdentityProvider, domain?: string, clientId?: string) => {
  switch (provider) {
    case IdentityProvider.AZURE:
      return LoginService.getAzureOAuthURL();
    case IdentityProvider.OKTA:
      if (domain) {
        return LoginService.getOktaOAuthURL(domain, clientId);
      }
      return;
    default:
      return;
  }
};

const LoginView = () => {
  const LOGIN_ERROR = "Login error";
  const UNKNOWN_ERROR = "Unknown error";

  const location = useLocation();

  const params = useMemo(() => {
    let paramsString = location.hash && location.hash[0] === "#" ? location.hash.substring(1) : location.hash;
    paramsString = paramsString.indexOf("?") > -1 ? paramsString.substring(0, paramsString.indexOf("?")) : paramsString;
    return new URLSearchParams(paramsString);
  }, [location]);

  const loginType = params.get("type") ?? "all";

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const sessionContext = useContext(SessionContext);

  const history = useHistory();

  const isAzureOAuthAvailable = environment.azureOAuth2 !== undefined && (loginType === "all" || loginType === "azure");

  const isOktaOAuthAvailable = environment.oktaOAuth2 !== undefined && (loginType === "all" || loginType === "okta");

  const oktaData = StorageService.get(OKTA_DATA);
  const defaultOktaDomain = loginType === "okta" ? params.get("domain") ?? "" : oktaData?.oktaDomain ?? "";
  const [oktaDomain, setOktaDomain] = useState(defaultOktaDomain);

  const initClientId =
    params.get("clientId") ?? (oktaData?.oktaDomain === defaultOktaDomain ? oktaData?.clientId : undefined);
  const [oktaClientId, setOktaClientId] = useState(initClientId);

  useEffect(() => {
    const oktaData = StorageService.get(OKTA_DATA);
    const paramDomain = params.get("domain");
    const paramClientId = params.get("clientId");
    const newClientId =
      (paramDomain === oktaDomain ? paramClientId : undefined) ??
      (oktaData?.oktaDomain === oktaDomain ? oktaData?.clientId : undefined);
    setOktaClientId(newClientId);
  }, [oktaDomain, params]);

  const goToSignIn = (provider: IdentityProvider, domain?: string, clientId?: string) => {
    setErrorMessage("");
    try {
      const url = getIdentityProviderUrl(provider, domain, clientId);
      if (url) {
        setIsLoading(true);
        window.location.href = url;
      }
    } catch (err: any) {
      setErrorMessage(err?.message ? err.message : UNKNOWN_ERROR);
    }
  };

  const doSancusLogin = useCallback(
    (idp: string) => {
      const idToken = idp === "azure" ? params.get("id_token") : params.get("access_token");
      const access_token = idp === "azure" ? params.get("access_token") : params.get("access_token");
      const state = params.get("state");
      const oktaDomain = idp === "okta" ? state ?? "" : "";
      if (idToken) {
        setIsLoading(true);
        setErrorMessage("");
        LoginService.loginHaidentity({
          accessToken: access_token ?? "",
          idToken,
          idp: idp === "azure" ? "b2c" : "okta",
          params:
            idp === "azure" ? { TFP: environment.azureOAuth2.tfp } : { DOMAIN: oktaDomain, AUTH_SERVER: "/default" },
        }).subscribe({
          next: (res) => {
            const haiData = res.data;
            if (haiData.ok && haiData.haiUser) {
              const expires_on = getTokenExpirationDate(haiData);
              const haiDataWithExpirationDate: ITokenExchangeResultWithExpiration = { ...haiData, expires_on };
              StorageService.set(HAI_DATA, haiDataWithExpirationDate);
              const routeKey = selectRouteKey(haiData.haiUser, haiData.roleDefinitions!);
              history.push(AppRoutes[routeKey].to);
            } else {
              setErrorMessage(LOGIN_ERROR + (haiData.message ? " - " + haiData.message : ""));
              setIsLoading(false);
            }
          },
          error: (err) => {
            setErrorMessage(LOGIN_ERROR + (err?.message ? " - " + err.message : ""));
            setIsLoading(false);
          },
        });
      } else {
        setErrorMessage(UNKNOWN_ERROR);
      }
    },
    [history, params],
  );

  useEffect(() => {
    if (isAzureOAuthAvailable && location.pathname.endsWith("/azure")) {
      doSancusLogin("azure");
    } else if (isOktaOAuthAvailable && location.pathname.endsWith("/okta")) {
      doSancusLogin("okta");
    }
  }, [doSancusLogin, isAzureOAuthAvailable, isOktaOAuthAvailable, location.pathname]);

  const [isOktaLoginEnabled, setIsOktaLoginEnabled] = useState(loginType === "okta");

  if (sessionContext.getHaiData()) {
    const { haiUser, roleDefinitions } = sessionContext.getHaiData();
    return <Redirect to={AppRoutes[selectRouteKey(haiUser, roleDefinitions)].to} />;
  } else {
    return (
      <div className={style.LoginContainer}>
        <Form className={style.Form}>
          <FormContext.Consumer>
            {(formContext) => (
              <>
                <FormGroup>
                  <span className={style.haivisionLogo}>
                    <HaivisionLogoComponent />
                  </span>
                  <span className={style.hubLogo}>
                    <HubSupportPortalLogoComponent />
                  </span>
                </FormGroup>
                {formContext.isSubmitting || isLoading ? (
                  <FormGroup>
                    <Spinner size={SpinnerSize.LARGE} />
                  </FormGroup>
                ) : (
                  <>
                    <FormGroup>
                      <Button
                        onClick={() => goToSignIn(IdentityProvider.AZURE)}
                        size="long"
                        disabled={!isAzureOAuthAvailable}
                      >
                        LOGIN WITH OFFICE 365
                      </Button>
                    </FormGroup>
                    <FormGroup>
                      <div className={style.oktaDomain} style={{ height: isOktaLoginEnabled ? "40px" : 0 }}>
                        <FormControl
                          placeholder="OktaDomain ID"
                          value={oktaDomain}
                          onChange={(ev: InputEvent) => setOktaDomain((ev.target as HTMLInputElement).value)}
                        />
                      </div>
                      <Button
                        onClick={() =>
                          isOktaLoginEnabled
                            ? goToSignIn(IdentityProvider.OKTA, oktaDomain, oktaClientId)
                            : setIsOktaLoginEnabled(true)
                        }
                        size="long"
                        disabled={isOktaLoginEnabled ? !oktaDomain : !isOktaOAuthAvailable}
                      >
                        LOGIN WITH OKTA
                      </Button>
                    </FormGroup>
                  </>
                )}
                <FormGroup>
                  <FormControlError show={!!errorMessage} mssg={errorMessage} />
                </FormGroup>
              </>
            )}
          </FormContext.Consumer>
        </Form>
      </div>
    );
  }
};

export default withRouter(LoginView);
