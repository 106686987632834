import { IHaiUser, ITokenExchangeResult } from "@hai/sancus-lib";
import React from "react";

import StorageService, { HAI_DATA } from "../utils/StorageService";

interface ISessionContextValue {
  getHaiData: () => Required<ITokenExchangeResult>;
  getHaiUser: () => IHaiUser;
}

const getHaiData = (): Required<ITokenExchangeResult> => {
  return StorageService.get(HAI_DATA);
};

const getHaiUser = (): IHaiUser => {
  return getHaiData().haiUser;
};

const contextValue = {
  getHaiData,
  getHaiUser,
};

export const SessionContext = React.createContext<ISessionContextValue>(contextValue);

export const SessionContextProvider = (props: any) => {
  return <SessionContext.Provider value={contextValue}>{props.children}</SessionContext.Provider>;
};
