import { isMainWindow, StorageService } from "@hai/hub-common-portal";
import { ITokenExchangeResult } from "@hai/sancus-lib";
import { logger } from "shared/logger";
import { HAI_DATA } from "./common/utils/StorageService";
import { ITokenExchangeResultWithExpiration, LoginService } from "./login/Login.service";
import { getTokenExpirationDate } from "./login/Login.view";

const REFRESH_INTERVAL_MIN = 5;
const EXPIRATION_TIME_LIMIT_MIN = 2 * REFRESH_INTERVAL_MIN;
const TOKEN_REFRESH_IFRAME_ID = "refresh-iframe";

const doTokenRefresh = (iframe: HTMLIFrameElement): void => {
  const oAuthUrl = LoginService.getAzureOAuthURL();
  iframe.setAttribute("src", oAuthUrl);
};

const tokenCheck = () => {
  const loggedUser = StorageService.get<ITokenExchangeResultWithExpiration>(HAI_DATA);
  if (!loggedUser) {
    return;
  }

  const minutesToExpire = Math.floor((new Date(loggedUser.expires_on).valueOf() - new Date().valueOf()) / 1_000 / 60);
  if (!minutesToExpire || minutesToExpire < EXPIRATION_TIME_LIMIT_MIN) {
    logger.log(`${minutesToExpire} minutes to token expiration. Let's refresh it now!`);
    refreshAzureToken();
  } else {
    logger.log(`${minutesToExpire} minutes to token expiration. We are fine...`);
  }
};

export const refreshAzureToken = () => {
  const iframe = document.getElementById(TOKEN_REFRESH_IFRAME_ID) as HTMLIFrameElement;
  if (!iframe) {
    window.location.href = "/";
  } else {
    doTokenRefresh(iframe);
  }
};

export const extractLoggedUserFromUrl = (): ITokenExchangeResult => {
  const url = window.location.href;
  const rawParams = url.substring(url.indexOf("#") + 1);
  const paramValues = rawParams
    .split("&")
    .map((paramValue) => {
      const [name, value] = paramValue.split("=");
      return {
        name,
        value,
      };
    })
    .filter((nameValue) => nameValue.name !== "state");
  const azureLoginParams: { [key: string]: string } = {};
  paramValues.forEach((param) => {
    if (param) {
      azureLoginParams[param.name] = param.value;
    }
  });
  return { ...azureLoginParams, ok: true };
};

const appendIFrame = () => {
  if (!document.getElementById(TOKEN_REFRESH_IFRAME_ID)) {
    const iframe = document.createElement("iframe");
    iframe.id = TOKEN_REFRESH_IFRAME_ID;
    iframe.style.width = "1px";
    iframe.style.height = "1px";
    iframe.style.position = "absolute";
    iframe.style.top = "-10px";
    iframe.style.left = "-10px";
    iframe.style.border = "0";
    iframe.src = "about:blank";
    document.body.appendChild(iframe);
  }
};

if (isMainWindow()) {
  appendIFrame();
  tokenCheck();
  setInterval(() => {
    tokenCheck();
  }, REFRESH_INTERVAL_MIN * 60 * 1_000);
} else {
  const extractedLoggedUser = extractLoggedUserFromUrl();
  if (extractedLoggedUser?.hat) {
    const expires_on = getTokenExpirationDate(extractedLoggedUser);
    const hydratedExtractedLoggedUser: ITokenExchangeResultWithExpiration = {
      ...extractedLoggedUser,
      expires_on,
    };
    StorageService.set<ITokenExchangeResultWithExpiration>(HAI_DATA, hydratedExtractedLoggedUser);
    if (window.frameElement) {
      (window.frameElement as HTMLIFrameElement).setAttribute("src", "about:blank");
    }
  }
}
